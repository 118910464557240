import React from "react"
import styled from "styled-components"
import Theme from "./Theme"
import "./layout.css"
import "./utility.css"
import { useState } from "react"
import Sidebar from "./sidebar"
import SlideOutMenu from "./slide-out-menu"
import Main from "./main"
import Modal from "./modal"
import SEO from "./seo"
import Secondary from "./secondary"

const GRID_WIDTH = "75px"

//=============================
//=== 🧱🧱 COMPONENT 🧱🧱 ===
//=============================
// layout consists of three columns
// ==============================
// | sidebar | slide-out | main |
// ==============================
// * sidebar is the menu and configurable in its related files
// * slide-out & main are configurable components with a similar api/design pattern
//   - each is broken up into a header and its content
//   - default styles are applied for uniform look and feel but can be overridden in each component
//     as shown in the table bellow
//
//   Naming convention = columnRowType
//   =================================
//     - columns = ["slideOut", "main"]
//     - rows = ["Header", "Content"]
//     - type = ["","Component"]
//
//

// TODO
// swap table around col<=>head
//              || HEADER - default || HEADER - custom         || CONTENT - default || CONTENT - custom         ||
// ||===========================================================================================================||
// || slide-out || slideOutHeader   || slideOutHeaderComponent || slideOutContent   || slideOutContentComponent ||
// || main      || mainHeader       || mainHeaderComponent     || mainContent       || mainContentComponent     ||
// ||===========================================================================================================||

const Layout = ({
  // main
  mainHeader, // default styles
  mainHeaderComponent, // custom component/styling
  mainContent, // default styles
  mainContentComponent, // custom component/styling
  mainBottom, // default styles
  // useful for monitoring slide out state
  // ---------
  // slide-out
  slideOutHeader, // default styles
  slideOutHeaderComponent, // custom component/styling
  slideOutContent, // default styles
  slideOutContentComponent, // custom component/styling
  // slideOutContent = [{title:"string", content: <Component />}]
  slideOutInitial = false, // set drawers open/closed initial state
  seo,
}) => {
  const [open, setOpen] = useState(slideOutInitial)

  // allows all components passed to layout to either be a component (normal use case)
  // or a function (render prop) - this is used to share state from layout with child compoents
  const injectState = component => {
    if (typeof component === "function") return component({ open, setOpen })
    return component
  }

  return (
    <Theme>
      <SEO {...seo} />
      <Container>
        <Sidebar />
        {slideOutContent ||
          (slideOutContentComponent && (
            <Secondary>
              <SlideOutMenu
                header={injectState(slideOutHeader)}
                headerComponent={injectState(slideOutHeaderComponent)}
                content={injectState(slideOutContent)}
                contentComponent={injectState(slideOutContentComponent)}
                open={open}
                setOpen={setOpen}
              />
            </Secondary>
          ))}
        <Main
          header={injectState(mainHeader)}
          headerComponent={injectState(mainHeaderComponent)}
          content={injectState(mainContent)}
          bottomRow={injectState(mainBottom)}
          contentComponent={injectState(mainContentComponent)}
          open={open}
        />
        <Modal />
      </Container>
    </Theme>
  )
}

//=============================
//==== 💅💅 STYLES 💅💅 =====
//=============================

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  width: 100%;
  position: relative;
  > div > *:first-child:not(.modal) {
    height: ${GRID_WIDTH};
    width: 100%;
    display: flex;
  }
  @media (max-width: 786px) {
    min-height: (100vh - 70px);
    padding-bottom: 70px;
  }
`

export default Layout
