import React from "react"
import styled from "styled-components"

//=============================
//=== 🧱🧱 COMPONENT 🧱🧱 ===
//=============================
const Header = ({ children }) => {
  return <Container>{children}</Container>
}

//=============================
//==== 💅💅 STYLES 💅💅 =====
//=============================
const Container = styled.div`
  font-size: 24px;
  font-weight: 500;
  display: flex;
  line-height: 1.1;
  padding: 10px 100px 10px 30px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
`

export default Header
