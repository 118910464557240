import React from "react"
import { ThemeProvider } from "styled-components"
import { useTheme } from "./theme-manager"

//=============================
//=== 🧱🧱 COMPONENT 🧱🧱 ===
//=============================
const Secondary = ({ children }) => {
  const { altTheme } = useTheme()
  return <ThemeProvider theme={altTheme}>{children}</ThemeProvider>
}

export default Secondary
