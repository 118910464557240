import React from "react"
import { ThemeProvider, createGlobalStyle } from "styled-components"
import { IconContext } from "react-icons"
import { useTheme } from "./theme-manager"

const GlobalStyles = createGlobalStyle`
  body {
    font-family: Roboto ;
    font-size: 1em;
  }
  h1{
    font-size: 3.052em;
  }
  h2{
    font-size: 2.441em;
  }
  h3{
    font-size: 1.953em;
  }
  h4{
    font-size: 1.563em;
  }
  p{
    font-size: 1em;
  }
  .message {
      padding: 8px 25px;
      border-radius: 5px;
      margin: 20px 0;
      width: calc(100% - 50px);
      max-width:850px;
      &.centered {
        text-align:center;
      }
      &.danger {
        color: #da4652;
        background: #ffd0c0;
        border: 1px solid #ffb09f;
      }
      &.info {
        color: #026EDB;
        background: #CCEDFE;
        border: 1px solid #98D8FB;
      }
  }
`

//=============================
//=== 🧱🧱 COMPONENT 🧱🧱 ===
//=============================
const Theme = ({ children }) => {
  const { theme } = useTheme()

  return (
    <IconContext.Provider value={{ style: { verticalAlign: "middle" } }}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {children}
      </ThemeProvider>
    </IconContext.Provider>
  )
}

//=============================
//==== 🔧🔧 HELPERS 🔧🔧 ====
//=============================

export default Theme
