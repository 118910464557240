import React from "react"
import styled from "styled-components"
import ExpandingItem from "./expanding-item"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"

import { Scrollbars } from "react-custom-scrollbars"

//=============================
//=== 🧱🧱 COMPONENT 🧱🧱 ===
//=============================
const SlideOutMenu = ({
  header,
  headerComponent,
  content = [],
  contentComponent,
  open,
  setOpen,
}) => {
  return (
    <Container open={open}>
      <Desktop open={open}>
        <Back>
          {headerComponent || header}
          <ExpandButton open={open} onClick={() => setOpen(!open)}>
            {open ? <IoIosArrowBack /> : <IoIosArrowForward />}
          </ExpandButton>
        </Back>
        <Content className={open ? "open" : "closed"}>
          {contentComponent ||
            content.map(item => <ExpandingItem item={item} />)}
        </Content>
      </Desktop>
      <Mobile open={open}>
        <Scrollbars style={{ width: 300, height: "100vh" }}>
          <Back>
            {headerComponent || header}
            <ExpandButton open={open} onClick={() => setOpen(!open)}>
              {open ? <IoIosArrowBack /> : <IoIosArrowForward />}
            </ExpandButton>
          </Back>
          <Content>
            {contentComponent ||
              content.map(item => <ExpandingItem item={item} />)}
          </Content>
        </Scrollbars>
      </Mobile>
    </Container>
  )
}

//=============================
//==== 💅💅 STYLES 💅💅 =====
//=============================
const Mobile = styled.div`
  display: none;
  position: fixed;
  top: 0;
  z-index: 2;
  transition: 0.2s all ease-in-out;
  ${({ open }) =>
    open
      ? `
      box-shadow: 1px 0 2px 1px #0000009e;
    width: 0px;
    overflow: hidden;
    `
      : `

    width: 300px;
  `}
  width: ${({ open }) => (open ? WIDTH : "0px")};
  overflow: ${({ open }) => (open ? "" : "hidden")};
  @media only screen and (max-width: 786px) {
    display: block;
    height: 100%;
    background: ${({ theme: { getColor } }) => getColor(200)};
  }
`
const Desktop = styled.div`
  display: block;
  transition: 0.2s all ease-in-out;
  width: ${({ open }) => (open ? WIDTH : "0px")};
  /* padding-left: ${({ open }) => (open ? "30px" : "0px")}; */
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 786px) {
    display: none !important;
  }
`

const WIDTH = "320px"
const Content = styled.div`
  width: ${WIDTH};
  /* padding-left: 15px; */
  transition: 0.3s all linear;
  &.closed {
    height: 0;
    overflow: hidden;
  }
  &.open {
    height: 100%;
  }
`

const Container = styled.div`
  transition: 0.2s all ease-in-out;
  width: ${({ open }) => (open ? WIDTH : "0px")};
  background: ${({ theme: { getColor } }) => getColor(200)};
  position: relative;
  @media only screen and (max-width: 786px) {
    display: none !important;
  }
`
const Back = styled.div`
  background: blue;
  position: relative;
  @media only screen and (max-width: 786px) {
    display: none !important;
  }
`

const ExpandButton = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  transform: translate(50%, -50%);
  padding: 5px;
  background: white;
  border-radius: 200px;
  cursor: pointer;
  z-index: 2;
  box-shadow: 0 0 1px 1px #0000000a;

  @media only screen and (max-width: 786px) {
    ${({ open }) => (open ? "transform: translate(-100%, -50%);" : "")};
  }
`

export default SlideOutMenu
