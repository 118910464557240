import React, { useState } from "react"
import { TwitterPicker } from "react-color"
import styled from "styled-components"
import { useTheme } from "./theme-manager"
import { getPallete } from "./colors"

const ThemeColorPicker = () => {
  const { updateTheme, predifined } = useTheme()

  const colors = predifined.map(color => getPallete(color)[500][0])
  const lookup = predifined.reduce(
    (a, c) => ({ ...a, [getPallete(c)[500][0]]: c }),
    {}
  )

  const random = () => "#" + Math.floor(Math.random() * 16777215).toString(16)

  const [color, setColor] = useState("#FFFFFF")
  const handleChange = ({ hex }) => {
    setColor(hex)
  }

  const handleChangeComplete = ({ hex }) => {
    const color = hex.toUpperCase()
    const update = lookup[color] ? lookup[color] : color
    updateTheme(update)
  }

  return (
    <Container>
      <TwitterPicker
        color={color}
        onChange={handleChange}
        onChangeComplete={handleChangeComplete}
        colors={[...colors, random()]}
        triangle="top-right"
      />
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  .twitter-picker > div > span > div {
    transition: 0.2s all ease-in-out;
    &:hover {
      transform: scale(1.2);
    }
  }
`

export default ThemeColorPicker
