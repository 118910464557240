import React from "react"
import styled from "styled-components"
import Header from "../shared/header"
import Inner from "./inner"
import ThemeColorPicker from "./theme-color-picker"
import { useState } from "react"
import posed from "react-pose"
import { FiSettings } from "react-icons/fi"
import logo from "../../images/logo.png"

//=============================
//=== 🧱🧱 COMPONENT 🧱🧱 ===
//=============================
const Main = ({
  header,
  headerComponent,
  content,
  contentComponent,
  bottomRow,
  open: slideOpen,
}) => {
  const [open, setOpen] = useState(false)

  return (
    <Container className={slideOpen ? "slideOpen" : ""}>
      <Top>
        {headerComponent ? headerComponent : <Header>{header}</Header>}
        <Settings onClick={() => setOpen(!open)}>
          <FiSettings size="25px" />
        </Settings>
        <a href="https://lacted.org/" target="_blank" rel="noopener noreferrer">
          <img src={logo} alt="IABLE" />
        </a>
      </Top>
      <Slide pose={open ? "open" : "closed"}>
        <ThemeColorPicker />
      </Slide>
      <Space />
      {contentComponent ? (
        contentComponent
      ) : (
        <Page className="page" slideOpen={slideOpen}>
          <Inner className="inner">{content}</Inner>
        </Page>
      )}
      {bottomRow && (
        <BottomTray>
          <Inner className="inner">{bottomRow}</Inner>
        </BottomTray>
      )}
    </Container>
  )
}

//=============================
//==== 💅💅 STYLES 💅💅 =====
//=============================

const Space = styled.div`
  height: 75px;
`
const BottomTray = styled.div`
  background: #eeeeee;
  padding: 30px 0;
  margin-top: 45px;
  .inner {
    margin-left: 0 !important;
  }
`

const SlideDown = posed.div({
  open: { y: 95, height: "auto" },
  closed: { y: -150, height: 0 },
})
const Slide = styled(SlideDown)`
  position: fixed;
  transform: translateY(-150px);
  right: 15px;
  z-index: 3;
  overflow: hidden;
`

const Container = styled.div`
  min-width: 800px;
  &.slideOpen {
    min-width: calc(800px - 400px);
  }
  @media only screen and (max-width: 900px) {
    min-width: calc(100% - 80px);
  }
  height: 100%;
  z-index: 1;
  .inner {
    margin: unset;
    @media only screen and (max-width: 1024px) {
      margin: 0 auto;
    }
  }
`

const Page = styled.div`
  /* padding: 0 30px 0 50px; */
  background: white;
  height: 100%;
  position: relative;
  /* width: calc(100% - ${({ slideOpen: o }) => (o ? "100px" : "500px")}); */

  @media only screen and (max-width: 786px) {
    padding: 0;
  }
`
const Top = styled.div`
  background: ${({ theme: { getColor } }) => getColor(100)};
  color: ${({ theme: { getColor } }) => getColor(100, true)};
  z-index: 4;
  position: fixed;
  top: 0;
  a {
    top: 12px;
    right: 10px;
    position: fixed;
    display: block;
    img {
      max-width: 100%;
      width: 50px;
      margin: 0 !important;
    }
  }
`
const Settings = styled.div`
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  opacity: 0.3;
  position: fixed;
  top: 25px;
  right: 80px;
  &:hover {
    opacity: 1;
  }
  @media (max-width: 480px) {
    display: none;
  }
`

//=============================
//==== 🔧🔧 HELPERS 🔧🔧 ====
//=============================

export default Main
