import React from "react"
import styled from "styled-components"
import posed, { PoseGroup } from "react-pose"
import { useModal } from "./modal-provider"
import useBodyScrollLock from "../../../utils/useBodyScrollLock"

//=============================
//=== 🧱🧱 COMPONENT 🧱🧱 ===
//=============================
const Modal = () => {
  const { open, closeFn, component, config } = useModal()
  useBodyScrollLock(open)

  // if you wish to have functionality to close modal when someone clicks on the overlay
  // this prop must be spread onto all elements that shouldn't fire a close event
  // you will need to add this to all descendents, not just the high level parent wrapper
  const dontCloseAttribute = { "data-dont-close": "1b9d6bcd" }
  return (
    <PoseGroup>
      {open && (
        <Overlay
          key="modal"
          onClick={event => {
            event.preventDefault()
            const key = "data-dont-close"
            const elementDontCloseAttribute = event.target.getAttribute(key)
            if (elementDontCloseAttribute !== dontCloseAttribute[key]) closeFn()
          }}
        >
          <Content config={config} className="modal">
            {React.cloneElement(component, { dontCloseAttribute })}
          </Content>
        </Overlay>
      )}
    </PoseGroup>
  )
}

//=============================
//==== 💅💅 STYLES 💅💅 =====
//=============================
const SlideUp = posed.div({
  enter: { y: 0, opacity: 1, delay: 300 },
  exit: { y: "50vh", opacity: 0 },
})
const Content = styled(SlideUp)`
  border-radius: 4px;
  padding: ${({ config: { padding } }) => padding};
  transition: 0.2s all ease-in-out;
  width: ${({ config: { width } }) => width};
  /* height: ${({ config: { height } }) => height}; */
`

const Shade = posed.div({
  enter: { opacity: 1 },
  exit: { opacity: 0, delay: 300 },
})
const Overlay = styled(Shade)`
  background: rgba(0, 0, 0, 0.78);
  backdrop-filter: blur(7px);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 3;
  display: flex;
  flex-direction: flex;
  align-items: center;
  justify-content: center;
`

export default Modal
