import React from "react"
import styled from "styled-components"
import { useState } from "react"

//=============================
//=== 🧱🧱 COMPONENT 🧱🧱 ===
//=============================
const ExpandingItem = ({ item }) => {
  const [open, setOpen] = useState(false)
  return (
    <Container>
      <Heading onClick={() => setOpen(!open)}>{item.label}</Heading>
      <Content open={open}>
        <Padding>{item.content}</Padding>
      </Content>
    </Container>
  )
}

//=============================
//==== 💅💅 STYLES 💅💅 =====
//=============================
const Container = styled.div`
  overflow: hidden;
  border-bottom: 1px solid black;
`
const Heading = styled.div`
  padding: 10px;
`
const Content = styled.div`
  transition: 0.2s all ease-in-out;
  height: ${({ open }) => (open ? "auto" : 0)};
`
const Padding = styled.div`
  padding: 10px;
`

export default ExpandingItem
