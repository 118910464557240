import React from "react"
import styled from "styled-components"
import { Link, useStaticQuery } from "gatsby"
import { graphql } from "gatsby"
import { IoMdBook, IoIosLogOut, IoIosLogIn, IoMdHelp } from "react-icons/io"
import { FaRegBookmark } from "react-icons/fa"
import { GoInfo, GoSearch } from "react-icons/go"
import { AiOutlineMessage } from "react-icons/ai"
import NavIcon from "../layout/slide-out-menu/navIcon"
import posed from "react-pose"

import useGlobalState from "../../utils/useGlobalState"

//=============================
//=== 🧱🧱 COMPONENT 🧱🧱 ===
//=============================
const Sidebar = () => {
  const {
    wordpressAcfOptions: {
      options: {
        publication_icon: { source_url: url },
        publication_name,
        enable_bookmarking,
      },
    },
  } = useStaticQuery(PUB_OPTIONS)
  const [auth, setAuth] = useGlobalState()

  const [open, setOpen] = React.useState(false)

  var ref = React.useRef()
  React.useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) setOpen(false)
    }
    if (open) {
      document.addEventListener("click", handleClickOutside)
      return () => document.removeEventListener("click", handleClickOutside)
    }
  }, [ref, open])

  const items = [
    { label: "Index", to: "/", icon: <IoMdBook /> },
    { label: "About", to: "/about/", icon: <GoInfo /> },
    { label: "Search", to: "/search/", icon: <GoSearch /> },
    {
      label: "Feedback",
      href: `https://lacted.org/pwa-feedback/?pub=${publication_name}&subject=Feedback/Question%20about%20${publication_name}`,
      icon: <AiOutlineMessage />,
    },
  ]

  if (!!enable_bookmarking) {
    items.splice(1, 0, { label: "Bookmarks", to: "/bookmarks/", icon: <FaRegBookmark /> })
  }

  const menuItems = [
    { label: "Help", to: "/help/", icon: <IoMdHelp /> },
  ]

  if (((auth && !auth.hasAccess) || !auth?.user) && !!enable_bookmarking) {
    menuItems.push({ label: "Login", to: "/login/", icon: <IoIosLogIn /> })
  }
  return (
    <Container>
      <Icon as={Link} to="/" className="logo-link">
        <img src={url} alt="" />
      </Icon>
      <Items className="items">
        {items.map(({ label, to, icon, href }, key) => (
          <Item
            {...(href ? { as: "a", href, key } : { as: Link, to, key })}
            activeClassName="active"
            target={href ? "_blank" : "_self"}
          >
            {React.cloneElement(icon, { size: "30px" })}
            {label}
          </Item>
        ))}
        <Menu
          className="menu"
          {...(open ? {} : { onClick: () => setOpen(true) })}
        >
          <NavIcon isOpen={open} />
        </Menu>
        <ContentMobile pose={open ? "open" : "closed"}>
          {menuItems.map(({ label, icon, to, href }, idx) => (
            <NavItem
              length={items.length}
              idx={idx}
              key={idx}
              href={href || to}
              to={href || to}
              as={href ? "a" : Link}
              pose={open ? "open" : "closed"}
            >
              {icon}
              {label}
            </NavItem>
          ))}
          {auth && auth.hasAccess && auth?.user && (
            <NavItem
              onClick={() =>
                setAuth(p => ({
                  ...p,
                  hasAccess: false,
                  user: null,
                  token: null,
                }))
              }
              length={items.length}
              idx={items.length}
              key={items.length}
              pose={open ? "open" : "closed"}
            >
              <IoIosLogOut /> Log Out
            </NavItem>
          )}
        </ContentMobile>
        <Content ref={ref} pose={open ? "open" : "closed"}>
          {menuItems.map(({ label, icon, href, to }, idx) => (
            <NavItem
              length={items.length}
              idx={idx}
              key={idx}
              href={href || to}
              to={href || to}
              as={href ? "a" : Link}
              pose={open ? "open" : "closed"}
            >
              {icon}
              {label}
            </NavItem>
          ))}
          {auth && auth.hasAccess && auth?.user && (
            <NavItem
              onClick={() =>
                setAuth(p => ({
                  ...p,
                  hasAccess: false,
                  user: null,
                  token: null,
                }))
              }
              length={items.length}
              idx={items.length}
              key={items.length}
              pose={open ? "open" : "closed"}
            >
              <IoIosLogOut /> Log Out
            </NavItem>
          )}
        </Content>
      </Items>
    </Container>
  )
}

// ========= workspace =========

const NavItem = styled.a`
  padding: 20px;
  cursor: pointer;
  font-size: 18px;
  background: ${({ theme: { getColor } }) => getColor(300)};
  color: ${({ theme: { getColor } }) => getColor(300, true)};
  display: flex;
  align-items: center;
  text-decoration: none;
  svg {
    margin-right: 20px;
    transition: 0.2s all ease-in-out;
  }
  transition: 0.2s all ease-in-out;
  &:hover {
    background: ${({ theme: { getColor } }) => getColor(200)};
    color: ${({ theme: { getColor } }) => getColor(200, true)};
    svg {
      transform: scale(1.5);
    }
  }
  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme: { getColor } }) => getColor(500)};
  }
`

const SlideInMobile = posed.div({
  open: {
    x: 0,
    y: -60,
    height: "auto",
    transition: {
      x: { ease: "easeInOut", duration: 300 },
      height: { ease: "easeInOut", delay: 300 },
    },
  },
  closed: {
    x: 0,
    y: 0,
    height: 1,
    transition: {
      x: { ease: "easeInOut", delay: 300 },
      height: { ease: "easeInOut", duration: 300 },
    },
  },
})
const ContentMobile = styled(SlideInMobile)`
  background: ${({ theme: { getColor } }) => getColor(300)};
  width: 100%;
  height: 200px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
  height: 100px;
  transform: translateX(-200px) translateZ(0px);
  display: none;
  @media only screen and (max-width: 786px) {
    display: block;
  }
`

const SlideIn = posed.div({
  open: {
    x: 100,
    height: "auto",
    transition: {
      x: { ease: "easeInOut", duration: 300 },
      height: { ease: "easeInOut", delay: 300 },
    },
  },
  closed: {
    x: -200,
    height: 100,
    transition: {
      x: { ease: "easeInOut", delay: 300 },
      height: { ease: "easeInOut", duration: 300 },
    },
  },
})
const Content = styled(SlideIn)`
  background: ${({ theme: { getColor } }) => getColor(300)};
  width: 250px;
  height: 200px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
  height: 100px;
  transform: translateX(-200px) translateZ(0px);

  @media only screen and (max-width: 786px) {
    transform: translateX(-200px) translateZ(0px);
    display: none;
  }
`

//=============================
//==== 💅💅 STYLES 💅💅 =====
//=============================
const Menu = styled.div`
  position: fixed;
  bottom: 0;
  background: ${({ theme: { getColor } }) => getColor(500)};
  color: ${({ theme: { getColor } }) => getColor(500, true)};
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  svg {
    transition: 0.2s all ease-in-out;
  }
  &:hover {
    background: ${({ theme: { getColor } }) => getColor(600)};
    color: ${({ theme: { getColor } }) => getColor(600, true)};
    svg {
      transform: scale(1.3);
    }
  }
`
const Icon = styled.div`
  background: ${({ theme: { getColor } }) => getColor(400)};
  color: ${({ theme: { getColor } }) => getColor(100)};
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
const Container = styled.div`
  z-index: 2;
  width: 100px;
  img {
    height: 65px;
    width: auto;
  }
  .logo-link {
    /* position: fixed;
    top: 0;
    left: 0;
    width: 100px !important;
    z-index: 10; */
  }
  @media only screen and (max-width: 786px) {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    height: 60px;
    .logo-link {
      display: none !important;
    }
    .menu {
      position: relative;
      width: 60px;
      height: 60px;
      svg {
        margin: 0;
        width: 21px;
        height: 21px;
      }
    }
    .items {
      display: flex;
      flex-direction: row;
      overflow: inherit;
      width: 100%;
      justify-content: space-between;
      & > a {
        width: 60px;
        height: 60px;
        svg {
          margin: 0;
          width: 21px;
          height: 21px;
        }
        font-size: 0px;
      }
    }
  }
`

const Items = styled.div`
  background: ${({ theme: { getColor } }) => getColor(400)};
  color: ${({ theme: { getColor } }) => getColor(400, true)};
  height: 100%;
  overflow: hidden;
  position: relative;
  /* @media (min-width: 787px) {
    left: 0;
    top: 75px;
    position: fixed;
  } */
`

const Item = styled.div`
  color: ${({ theme: { getColor } }) => getColor(400, true)};
  height: ${100}px;
  width: ${100}px;
  border-bottom: 1px solid ${({ theme: { getColor } }) => getColor(300)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 18px;
  text-decoration: none;

  &.active {
    background: ${({ theme: { getColor } }) => getColor(200)};
  }

  svg {
    margin-bottom: 7px;
    transition: 0.2s all ease-in-out;
  }

  transition: 0.2s all ease-in-out;
  &:hover {
    background: ${({ theme: { getColor } }) => getColor(300)};
    svg {
      transform: scale(1.4);
    }
  }
`

//=============================
//==== 🔧🔧 HELPERS 🔧🔧 ====
//=============================
const PUB_OPTIONS = graphql`
  query OptionsPublication {
    wordpressAcfOptions {
      options {
        publication_name
        publication_icon {
          source_url
        }
        enable_bookmarking
      }
    }
  }
`

export default Sidebar
