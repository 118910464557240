import React from "react"
import styled from "styled-components"

const NavIcon = ({ isOpen }) => {
  return (
    <Icon className={isOpen ? "open" : "closed"}>
      <span />
      <span />
      <span />
      <span />
    </Icon>
  )
}
export default NavIcon

const Icon = styled.div`
  margin: 0;
  padding: 0;
  width: 30px;
  height: 25px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: ${({ theme: { getColor } }) => getColor(300, true)};
    border-radius: 10px;
    opacity: 0.9;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }
  span:nth-child(1) {
    top: 0px;
  }
  span:nth-child(2),
  span:nth-child(3) {
    top: 10px;
  }
  span:nth-child(4) {
    top: 20px;
  }
  &.open span:nth-child(1) {
    top: 10px;
    width: 0%;
    left: 50%;
  }
  &.open span:nth-child(2) {
    transform: rotate(45deg);
  }
  &.open span:nth-child(3) {
    transform: rotate(-45deg);
  }
  &.open span:nth-child(4) {
    top: 10px;
    width: 0%;
    left: 50%;
  }
`
